import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	setupSlideMenu();
	var banner_option = {
		"delay": 10000
	};
	window.kms_bannerRotate(banner_option);
	setupServiceCard();
	new FloatLabels(".js-float-wrap");
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
};

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");

		}
	});

	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}

export function setupServiceCard(){
	const service_cards = [...document.querySelectorAll(".service-card")];
	const service_content_list = [...document.querySelectorAll(".service-content")];
	service_cards.forEach(el => {
		el.addEventListener("click", ()=> {
			let setToInactive = false;
			if(el.classList.contains("active")){
				setToInactive = true;
			}
			service_content_list.forEach(service_content => {
				service_content.classList.remove("active");
			});
			service_cards.forEach(service_card => {
				service_card.classList.remove("active");
			});
			
			if(setToInactive){
				return;
			}

			el.classList.add("active");
			const id = el.dataset.id;
			const content = document.querySelector(".service-content[data-id=\""+ id +"\"]");
			content.classList.add("active");
			const service_content_area = document.getElementById("service-content=" + id);
			if (service_content_area){
				service_content_area.scrollIntoView({behavior: "smooth"});
			}
		});
	});
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});
